import styled from "styled-components"

import { colors, foregrounds } from "../constants/colors"
import { mq } from "../constants/media-queries"
import { Link } from "gatsby"

export const Button = styled(Link)`
  display: block;
  border: 1px solid transparent;
  border-color: ${({ color, outline }) => outline && colors[color]};
  background: ${({ color, outline }) => {
    if (outline) {
      return color === "white" ? "rgba(0,0,0,0.2)" : "rgba(255,255,255,0.2)"
    } else return colors[color]
  }};
  color: ${({ color, outline }) =>
    outline ? colors[color] : foregrounds[color]};
  font-family: "Oswald", sans-serif;
  font-size: 17px;
  padding: 0.85em 2em;
  ${mq.sm`
    padding: 0.85em 2.5em;
    font-size: 20px;
    &:hover, &:focus {
      padding: 0.85em 3em;
    }
    &:focus-visible {
      box-shadow: 0 0 0 2px ${({ color, outline }) =>
        outline ? colors[color] : foregrounds[color]};
    }
  `}
  text-transform: uppercase;
  border-radius: 99px;
  cursor: pointer;
  transition: ease-in-out 0.2s;
`
