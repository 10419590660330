import "../components/layout.css"

import React from "react"
import styled from "styled-components"

import { Button } from "../components/button"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Row } from "../components/styled"
import { svgs } from "../components/svgs"
import { colors } from "../constants/colors"
import { zIndexes } from "../constants/variables"
import dancer from "../images/dancer.png"
import fitnessGirl from "../images/fitness.png"
import { mq } from "../constants/media-queries"

const Hero = styled.header`
  height: 100%;
  overflow: hidden;
  position: relative;
`

const HeroRow = styled(Row)`
  max-width: 1400px;
  min-height: 100vh;
  height: 100vh;
  @supports (-moz-appearance: none) {
    height: 100%;
    min-height: unset;
  }
  display: flex;
  flex-direction: column;
  ${mq.sm`
    flex-direction: row;
    height: 100vh;
    @supports (-moz-appearance: none) {
      height: 100vh;
      min-height: unset;
  }
  `}
`

const TriangleSvg = styled.div`
  grid-area: 1/1/2/2;
  z-index: ${zIndexes.veryLow};
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-left: ${({ left }) => (left ? "-7vw" : "-1vw")};
  transform: ${({ left }) => !left && "rotate(90deg) translateX(-9vw)"};
  svg {
    max-width: 100%;
    width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`

const Dancer = styled.img`
  grid-area: 1/1/2/2;
  width: 65%;
  height: 100%;
  object-fit: contain;
  justify-self: center;
  transform: translateY(-3vh);
  z-index: ${zIndexes.veryLow};
`
const FitnessGirl = styled.img`
  grid-area: 1/1/2/2;
  z-index: ${zIndexes.veryLow};
  width: 40%;
  height: 100%;
  object-fit: contain;
  justify-self: end;
  margin-right: 8vw;
  ${mq.sm`
  margin-right: 5vw;
  transform: translateY(-4vh);
  `}
`

const BackgroundFadedTitle = styled.p`
  position: absolute;
  justify-self: center;
  grid-area: 1/1/2/2;
  z-index: ${zIndexes.veryLow};
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  color: ${({ dance }) =>
    dance ? "rgba(255, 255, 255, 0.07)" : "rgba(0, 0, 0, 0.07)"};
  font-size: ${({ dance }) => (dance ? "48vw" : "40vw")};
  ${mq.sm`
  position: static;
  font-size: ${({ dance }) => (dance ? "12vw" : "9vw")};
  transform: translateY(5vw);
`}
`

const Pane = styled.div`
  width: 100%;
  height: 50%;
  display: grid;
  align-items: center;
  padding: ${({ left }) => (left ? "80px 0 0" : "20px 0")};
  grid-template-rows: minmax(0, 1fr);
  ${mq.sm`
  height: 100%;
  width: ${({ left }) => (left ? "55%" : "45%")};
  padding: 18vh 0 8vh;
  `}
`

const LeftPaneBg = styled.div`
  position: absolute;
  top: -5vh;
  left: 0;
  transform: skewY(-5deg);
  background: linear-gradient(
    135deg,
    ${colors.black1} 30%,
    ${colors.black2} 80%
  );
  height: 65%;
  width: 100%;
  ${mq.sm`
  background: linear-gradient(
    135deg,
    ${colors.black1} 30%,
    ${colors.black2} 100%
    );
    top: 0;
    left: -10vw;
    height: 100vh;
  width: 65vw;
  transform: skewX(-5deg);
  `}
`

const RightPaneBg = styled.div`
  position: absolute;
  transform: skewY(-5deg);
  top: 52%;
  left: 0;
  background: linear-gradient(
    256.11deg,
    ${colors.greyB} 0%,
    ${colors.greyF} 90%
  );
  width: 100%;
  height: 100%;
  ${mq.sm`
    top: 0;
  left: unset;
    right: -10vw;
    transform: skewX(-5deg);
  background: linear-gradient(
    220deg,
    ${colors.greyB} 20%,
    ${colors.greyF} 110%
  );
    height: 100vh;
    width: 55vw;`}
`

const IndexButton = styled(Button)`
  justify-self: center;
  align-self: end;
  z-index: ${zIndexes.low};
  grid-area: 1/1/2/2;
  transform: translateY(-6vh);
  ${mq.sm`
  transform: ${({ left }) => (left ? "translateX(7vw)" : "translateX(-6vw)")};
`};
`

const IndexPage = () => (
  <Layout darkTheme>
    <SEO title="Accueil" />
    <Hero>
      <HeroRow>
        <Pane left>
          <LeftPaneBg />
          <BackgroundFadedTitle dance>danse</BackgroundFadedTitle>
          <TriangleSvg left>{svgs.triangle}</TriangleSvg>
          <Dancer src={dancer} alt="danseuse"></Dancer>
          <IndexButton to="/danse/" left color={"white"}>
            Vers la Danse
          </IndexButton>
        </Pane>
        <Pane>
          <RightPaneBg />
          <BackgroundFadedTitle fitness>fitness</BackgroundFadedTitle>
          <TriangleSvg>{svgs.triangle}</TriangleSvg>
          <FitnessGirl src={fitnessGirl} alt="fitness"></FitnessGirl>
          <IndexButton to="/fitness/" color={"black2"}>
            Vers le Fitness
          </IndexButton>
        </Pane>
      </HeroRow>
    </Hero>
  </Layout>
)

export default IndexPage
